@tailwind base;
@tailwind components;

@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
html, body{
    font-family: 'Raleway', sans-serif;
    overflow-x: hidden;
}

.alto{
    height: calc(100vh - 2.5rem);
    overflow-y: auto;
}

.lista{
    height: calc(100vh - 2.5rem - 15rem);
    overflow-y: scroll;
}

.popup-content {
    position: absolute !important;
    left:25% !important;
    top:30% !important;
    width: 50% !important;
}